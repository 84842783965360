@use 'libs/core/src/lib/styles/themes' as *;

.figma-upload-header {
  @include themify {
    padding: themed('spacing', S);

    .back-link-arrow {
      .icon-arrow-caret-left {
        filter: themed('colors', 'cta-link-filter');
      }
    }
  }
}
