@use 'libs/core/src/lib/styles/themes';

.figma-assets-list-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include themes.themify {
    .figma-section-header {
      margin-bottom: themes.themed('spacing', S);
    }
  }
}
