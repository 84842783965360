@use 'libs/core/src/lib/styles/themes';

.figma-upload-form-container {
  @include themes.themify {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #e7e7e7;
    background-color: white;

    .form-title {
      padding: themes.themed('spacing', 'L');
    }

    .figma-upload-cards-container {
      width: 100%;
      padding: 0 themes.themed('spacing', 'L');
    }

    .figma-upload-card {
      align-items: center;
      border-top: 1px solid #e7e7e7;
      display: flex;
      flex-direction: row;
      height: 67px;
      width: 100%;
      justify-content: space-between;
      &__last {
        border-bottom: 1px solid #e7e7e7;
      }

      .file-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .img-thumbnail {
          width: 50px;
          max-height: 35px;
          object-fit: contain;
        }

        .filename {
          margin-left: 20px;
        }

        .filename-input {
          margin-left: 15px;
          min-height: 32px;
          max-width: 230px;
          width: 100%;
          padding-left: themes.themed('spacing', 'XXS');
        }
      }

      .buttons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .trash-icon-button {
          color: #4a4a4a !important;
          margin-left: themes.themed('spacing', 'S');
        }
      }
    }

    .upload-button-container {
      position: sticky;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.95);
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      .upload-button {
        margin-top: themes.themed('spacing', 'M');
        margin-bottom: themes.themed('spacing', 'M');
      }
    }

    .save-button {
      height: 32px !important;
      width: 67px !important;
      margin-left: 18px;
    }

    .cancel-button {
      height: 32px !important;
      width: 67px !important;
      margin-left: 6px;
    }
  }

}

.Toastify__toast-container.top-level-toast-container {
  bottom: 0 !important;
  max-width: 485px !important;
  width: 100% !important;
  .Toastify__toast {
    background-color: #04b08c !important;
    min-height: 0 !important;
    margin-bottom: 0 !important;
  }
}
